import React from 'react'
import Layout from '../components/layout'
import privacyImg from '../images/header/o_nama.svg'
import { Wrapper, Section } from '../components/baseStyles'
import Paragraph from '../components/paragraph'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import H3 from '../components/h3'
import SEO from '../components/seo'

const Section1 = styled(Section)`
  padding-top: 25px;
`

export default function PrivacyPolicy({ location, pageContext: { locale } }) {
  return (
    <Layout
      locale={locale}
      isPrivacy={true}
      heroImage={privacyImg}
      title={
        locale === 'hr'
          ? 'Tko se boji GDPR-a još?'
          : 'Who’s afraid of the big bad GDPR?'
      }
    >
      <SEO
        title={
          locale === 'hr'
            ? 'Zaštita podataka'
            : 'Privacy terms'
        }
        description={
          locale === 'hr'
            ? 'Litto se obvezuje čuvati privatnost osobnih podataka koje distribuirate prilikom korištenja Litto web stranice'
            : 'Litto is committed to maintaining and managing the privacy of personal information that you provide when using the Litto website'
        }
        keywords={['Litto']}
        lang={locale}
      />
      <Wrapper style={{ marginTop: '50px' }}>
        <Section>
          <Paragraph>
            <FormattedMessage id="privacySettingsText1" />
          </Paragraph>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsText2" />
          </Paragraph>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsText3" />
          </Paragraph>
          <Paragraph>
            <FormattedMessage id="privacySettingsText4" />
          </Paragraph>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsText5" />
          </Paragraph>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsText6" />
          </Paragraph>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsText7" />
          </Paragraph>
          <Paragraph>
            <FormattedMessage id="privacySettingsText8" />
          </Paragraph>
        </Section>

        <Section1>
          <H3>
            <FormattedMessage id="privacySettingsCookiesTitle" />
          </H3>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsCookiesText1" />
          </Paragraph>

          <Paragraph>
            <FormattedMessage id="privacySettingsCookiesText2" />
          </Paragraph>

          <Paragraph>
            <FormattedMessage id="privacySettingsCookiesText3" />
          </Paragraph>
        </Section1>

        <Section1>
          <H3>
            <FormattedMessage id="privacySettingsRightsTitle" />
          </H3>
          <Paragraph>
            <FormattedMessage id="privacySettingsRightsText1" />
          </Paragraph>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsRightsText2" />
          </Paragraph>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsRightsText3" />
          </Paragraph>
          <Paragraph offset="25px">
            <FormattedMessage id="privacySettingsRightsText4" />
          </Paragraph>
          <Paragraph offset="50px">
            <FormattedMessage id="privacySettingsRightsText5" />
          </Paragraph>
        </Section1>
      </Wrapper>
    </Layout>
  )
}
